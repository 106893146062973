.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(15px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  display: flex;
  flex-direction: column;
}

.footer {
  font-size: small;
  color: #fff;
  flex-shrink: 0;
  margin-bottom: 30px;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.footer-left{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-right: 5px;
}

.footer-right{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.footer div{
  padding: 10px;
  background-color: rgba(0,0,0,0.1);
  display: inline-block;
}

.footer svg{
  margin-right: 3px;
  margin-left: 3px;
}


.footer a{
  color: #7789ec;
  text-decoration: none;
}